import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import * as Icon from "react-feather"

export default function Content({setShowMobileSidebar}) {
    const location = useLocation();

    return (
        <div className="scrollbar-container main-menu-content overflow-hidden ps" onClick={()=>setShowMobileSidebar(false)}>
            <ul className="navigation navigation-main" >
                <li className={`nav-item ${location.pathname === '/dashboard' ? 'active' : ''}`}>
                    <Link to="/dashboard" className="d-flex justify-content-start">
                        <div className="menu-text">
                            <Icon.Home size={20} />
                            <span className="menu-item menu-title">Dashboard</span>
                        </div>
                    </Link>
                </li>
                <li className={`nav-item ${location.pathname === '/users-details' ? 'active' : ''}`}>
                    <Link to="/users-details" className="d-flex justify-content-start">
                        <div className="menu-text">
                            <Icon.User size={20} />
                            <span className="menu-item menu-title">Users</span>
                        </div>
                    </Link>
                </li>
                <li className={`nav-item ${location.pathname === '/affiliate' ? 'active' : ''}`}>
                    <Link to="/affiliate" className="d-flex justify-content-start">
                        <div className="menu-text">
                            <Icon.Link size={20} />
                            <span className="menu-item menu-title">Affiliate</span>
                        </div>
                    </Link>
                </li>
                <li className={`nav-item ${location.pathname === '/clubs-details' ? 'active' : ''}`}>
                    <Link to="/clubs-details" className="d-flex justify-content-start">
                        <div className="menu-text">
                            <Icon.Briefcase size={20} />
                            <span className="menu-item menu-title">Clubs</span>
                        </div>
                    </Link>
                </li>
                <li className={`nav-item ${location.pathname === '/token-management' ? 'active' : ''}`}>
                    <Link to="/token-management" className="d-flex justify-content-start">
                        <div className="menu-text">
                            <Icon.Codepen size={20} />
                            <span className="menu-item menu-title">Token</span>
                        </div>
                    </Link>
                </li>
                <li className={`nav-item ${location.pathname === '/coin-management' ? 'active' : ''}`}>
                    <Link to="/coin-management" className="d-flex justify-content-start">
                        <div className="menu-text">
                            <Icon.Pocket size={20} />
                            <span className="menu-item menu-title">Coins</span>
                        </div>
                    </Link>
                </li>
                <li className={`nav-item ${location.pathname === '/chips-management' ? 'active' : ''}`}>
                    <Link to="/chips-management" className="d-flex justify-content-start">
                        <div className="menu-text">
                            <Icon.Database size={20} />
                            <span className="menu-item menu-title">Chips</span>
                        </div>
                    </Link>
                </li>
                <li className={`nav-item ${location.pathname === '/challenge-management' ? 'active' : ''}`}>
                    <Link to="/challenge-management" className="d-flex justify-content-start">
                        <div className="menu-text">
                            <Icon.TrendingUp size={20} />
                            <span className="menu-item menu-title">Challenge</span>
                        </div>
                    </Link>
                </li>
                <li className={`nav-item ${location.pathname === '/coupon-management' ? 'active' : ''}`}>
                    <Link to="/coupon-management" className="d-flex justify-content-start">
                        <div className="menu-text">
                            <Icon.Link2 size={20} />
                            <span className="menu-item menu-title">Coupons</span>
                        </div>
                    </Link>
                </li>
                <li className={`nav-item ${location.pathname === '/news-management' ? 'active' : ''}`}>
                    <Link to="/news-management" className="d-flex justify-content-start">
                        <div className="menu-text">
                            <Icon.BookOpen size={20} />
                            <span className="menu-item menu-title">News</span>
                        </div>
                    </Link>
                </li>
            </ul>
        </div>
    )
}
