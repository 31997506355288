import React from "react"
import VerticalLayout from "./VerticalLayout"
import FullLayout from "./FullpageLayout"

const layouts = {
  vertical: VerticalLayout,
  full: FullLayout,
}

const ContextLayout = React.createContext()

class Layout extends React.Component {
  state = {
    activeLayout: "vertical",
    width: window.innerWidth,
    direction: "ltr" // options[String] : ltr / rtl
  }

  updateWidth = () => {
    this.setState({
      width: window.innerWidth
    })
  }

  componentDidMount = () => {
    if (window !== "undefined") {
      window.addEventListener("resize", this.updateWidth)
    }
    this.handleDirUpdate()
    this.setState({
      activeLayout: "vertical"
    })
  }

  componentDidUpdate() {
    this.handleDirUpdate()
  }

  handleDirUpdate = () => {
    let dir = this.state.direction
    if (dir === "rtl")
      document.getElementsByTagName("html")[0].setAttribute("dir", "rtl")
    else document.getElementsByTagName("html")[0].setAttribute("dir", "ltr")
  }



  render() {
    const { children } = this.props
    return (
      <ContextLayout.Provider
        value={{
          state: this.state,
          fullLayout: layouts["full"],
          VerticalLayout: layouts["vertical"],
          switchLayout: layout => {
            this.setState({ activeLayout: layout })
          },
          switchDir: dir => {
            this.setState({ direction: dir })
          }
        }}
      >
        {children}
      </ContextLayout.Provider>
    )
  }
}

export { Layout, ContextLayout }
