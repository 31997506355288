import React from "react"
import { Navbar } from "reactstrap"
import NavbarUser from "./NavbarUser"
import userImg from "../../../assets/img/portrait/small/avatar-s-11.jpg"
import {
  NavItem,
  NavLink,
} from "reactstrap"
import * as Icon from "react-feather"

const ThemeNavbar = props => {
  return (
    <React.Fragment>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <Navbar
        className="header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow navbar-light floating-nav navbar"
      >
        <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div
              className="navbar-collapse d-flex justify-content-between align-items-center"
              id="navbar-mobile"
            >
              <div className="bookmark-wrapper">
                <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
                  <ul className="navbar-nav d-xl-none">
                    <NavItem className="mobile-menu mr-auto">
                      <NavLink
                        className="nav-menu-main menu-toggle hidden-xs is-active"
                        onClick={() => props.setShowMobileSidebar(true)}
                      >
                        <Icon.Menu className="ficon" />
                      </NavLink>
                    </NavItem>
                  </ul>
                </div>
              </div>
              <NavbarUser
                handleAppOverlay={props.handleAppOverlay}
                changeCurrentLang={props.changeCurrentLang}
                userName={"Thanh Ho"}
                userImg={
                  userImg
                }
              />
            </div>
          </div>
        </div>
      </Navbar>
    </React.Fragment>
  )
}


export default ThemeNavbar
