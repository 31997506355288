import React, { useState } from "react";
import classnames from "classnames";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import Sidebar from "./Sidebar";

export default function VerticalLayout(props) {
  const [menuCollapsed, setMenuCollapsed] = useState(false)
  const [showMobileSidebar, setShowMobileSidebar] = useState(true)

  return (
    <div className={`wrapper vertical-layout theme-primary navbar-floating ${menuCollapsed ? 'menu-collapsed' : ''}`} >
      <Sidebar showMobileSidebar={showMobileSidebar} setShowMobileSidebar={setShowMobileSidebar} menuCollapsed={menuCollapsed} setMenuCollapsed={setMenuCollapsed}/>
      <div className={classnames("app-content content")} >
        <Navbar setShowMobileSidebar={setShowMobileSidebar} />
        <div className="content-wrapper">{props.children}</div>
      </div>
      <Footer />
      <div className="sidenav-overlay" />
    </div>
  )
}

