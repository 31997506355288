import React, { useEffect, useState } from 'react'
import Content from './Content'
import Header from './Header'

export default function Index({menuCollapsed, setMenuCollapsed, showMobileSidebar, setShowMobileSidebar}) {
    const [collapsed , setCollapsed] = useState(true);
    const [width,  setWidth] = useState(window.innerWidth);

    const updateWidth = () => {
        setWidth(window.innerWidth)
        if(window.innerWidth < 1200){
            setMenuCollapsed(false)
        }else{
            setMenuCollapsed(true)
        }
    }
    
    useEffect(() => {
        if (window !== "undefined") {
          window.addEventListener("resize", updateWidth)
        }
    })

    return (
        <div onMouseOverCapture={()=>setCollapsed(false)} onMouseOutCapture={()=>setCollapsed(true)} className={`main-menu menu-fixed menu-light menu-accordion menu-shadow theme-primary ${width<1200 ? showMobileSidebar ? '' : "hide-sidebar" : collapsed ? 'collapsed' : ''}`}>
            <Header menuCollapsed={menuCollapsed} setMenuCollapsed={setMenuCollapsed} setShowMobileSidebar={setShowMobileSidebar}/>
            <Content setShowMobileSidebar={setShowMobileSidebar}/>
        </div>
    )
}
